import validate from "/mcc/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@20.17.19_db0@0.2.1_eslint@8.57.1_ioredis@_61b58d779dd5b05a12c1c5284fcdfcaa/node_modules/nuxt/dist/pages/runtime/validate.js";
import redirect_45global from "/mcc/src/middleware/redirect.global.ts";
import manifest_45route_45rule from "/mcc/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@20.17.19_db0@0.2.1_eslint@8.57.1_ioredis@_61b58d779dd5b05a12c1c5284fcdfcaa/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  redirect_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  admin: () => import("/mcc/src/middleware/admin.ts"),
  auth: () => import("/mcc/src/middleware/auth.ts"),
  committee: () => import("/mcc/src/middleware/committee.ts"),
  "public-only": () => import("/mcc/src/middleware/public-only.ts")
}